












import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Home extends Vue {
  completeImageList = [
    {
      src: require('../assets/1.png'),
      title: '此作品为禁毒公益海报设计。当下，人们无不有能 够洪福齐天、长命百岁的愿望，而人一旦沾染毒 品，便与福寿无缘。我将“毒”字与“寿”、“ 福”二字分别同构，使用辨识度高、易于同构的 宋体，以及不同颜色区分各字的笔画，令人乍一看似是“福”字和“寿”字，然而确有“毒”字的笔 画带来的不适感。我以这样的文字同构设计呼吁人 们远离“毒”，才能寿比南山，福如东海。此作品 获北航新媒体艺术与设计学院年展一等奖。(指导老 师:张雪)'
    },
    {
      src: require('../assets/2.png'),
      title: '基于中国科技馆发出的文创产品项目在设计上应该 集中国特色、科技内涵和实用性于一体的倡导，我 以手机支架为载体，结合明清特色锁扣、中式密码 锁和印刻纹样等元素设计了这款文创产品。密码锁 作为主体，整体立起时可支撑手机，密码根据用户 天干地支等个人信息进行定制 ，可拆卸，滚轮可 转动。拆去密码锁后，背板锁扣立起时同样可起支 撑作用，满足用户手机支撑高度需求。锁扣背板印 刻莲花纹，寓意高洁吉祥。中式密码锁的机械结构 是古人智慧的结晶，展示的是“原始的科技”，契 合了科技主题，整体采用做旧金属的材质，配合中 式纹样，展示中国风韵。此作品通过中国科技馆文 创产品设计评审。(指导老师:王珊)'
    },
    {
      src: require('../assets/3.png'),
      title: '中国先民把周天分为十二星次，用二十 八星宿来观测日、月、五星运行，并创 作了天宫图以记录这些信息，此款文创 产品灵感便是来自于此。具体设计上， 我将星宫图印于伞面，汉字星宫点缀于 边缘，星点采用特殊油墨印制，遇水可 变为彩色，伞内里采用中国红，彰显中 国内涵。细节上，手柄处使用植鞣革， 亲肤耐磨，手柄头焊金属龙头，彰示主 人尊贵身份，整个伞骨都使用钛合金， 抗风抗锈。无论晴天雨天，使用此伞， 都可谓古香古色，妙趣横生。此作品通 过中国科技馆文创产品设计评审。(指 导老师:王珊)'
    },
    {
      src: require('../assets/4.png'),
      title: '该组作品为标志设计，此处着重解说中国古玩网 站标志设计。中国古玩网交易平台LOGO需要体 现的是其金融交易功能与中国古玩古韵古香的特 色。本设计灵感来源于元明时期吉祥结镂空花钱 币，采用实物平面化的方法，利用古代货币一般 等价物的本质，体现了市场交易功能及古玩的历 史价值。吉祥结花钱具有辟邪、吉祥寓意，寄托 了中国古玩网交易平台渴望财源广进、生意兴 隆的愿望，同时花钱作为一种较为罕见的钱币文 物，也从侧面体现了古玩商品的收藏价值。(指 导老师:王可)'
    },
    {
      src: require('../assets/5.png'),
      title: '页面主要使用红黑白三色，风格倾向于时尚 简约，高档典雅。在第一板块使用了较强烈 的指针悬浮交互动效以吸引用户眼球;酒瓶 倒置的动作将用户视角引入第二板块，这里 利用缓慢流动的烟雾效果展现SAGA红酒的醇 厚浓香以及酒水商品的流动特性，并引入部 分文字简介。第三板块嵌入视频;第四板块 嵌入轮播图进一步做介绍;最后添加购买栏 目。页面中添加自适应功能、button及图文 简单交互动效、动态背景，并添加动画、汉 堡菜单等。(指导老师:王珊)'
    },
    {
      src: require('../assets/6.png'),
      title: '<pre>“方”形具有直线的特性,主要通过折线体现性格,带 有坚韧理性的特点，代表“刚”性。“圆”形则具 有曲线的特征,主要是由平滑的曲线组成,体现感性、 运动、柔美的特点，代表“柔”性。\n' +
        '此作品通过曲与直、方与圆、软与硬的对比产生的 和谐旋律，赋予立方体和球体某种材质，探索材质 对方圆特性的影响，使方圆产生碰撞和对比，用方 与圆最简单的线条和形态来阐释最洁净的空间。此 作品获北京航空航天大学新媒体艺术与设计学院年 展三等奖。(指导老师:徐静秋)</pre>'
    },
    {
      src: require('../assets/7.png'),
      title: '灵感是指艺术或科学活动中瞬间产生的富有创造性的突发思维状态，是对长于积累又具备脑力表达条件的人的最珍贵的宝物。从古至今的文人、艺术家无\n' +
        '    不祈祷渴望灵感的降临。该作品展现了一座灵感博物馆，无数灵感具象化的思维元素在这里联系、拼接、迸发、爆炸，灵感的形态或许是捉摸不定的烟雾\n' +
        '    粒子，或许是一根根活跃的纤维，或许是耀眼的金属......(指导老师:徐静秋)'
    }
  ]
}
