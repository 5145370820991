





































































import Vue from 'vue'

export default Vue.extend({
  name: 'App',

  components: {},

  data: () => ({
    //
  }),

  methods: {
    gocv () {
      window.location.href = 'https://public.veypi.com/yukiko/cv.pdf'
    }
  }
})
